<template>
  <viewcard--c
    :title="
      $route.params.id > 0
        ? 'Atualizar Job Ocorrência'
        : 'Cadastrar Job Ocorrência'
    "
    :btsave="null"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group label="Descrição da Ocorrência">
            <b-form-textarea disabled v-model="record.description" rows="3" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Categoria">
            <b-form-input disabled v-model="record.category" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Entidade">
            <b-form-input disabled v-model="record.entity" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Empresa">
            <b-form-input disabled v-model="record.company" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Professor">
            <b-form-input disabled v-model="record.teacher" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Operador">
            <b-form-input disabled v-model="record.operator_name" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Evento/Aula">
            <b-form-input disabled v-model="record.event_or_lesson" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="ID">
            <b-link
              class="underline"
              :to="`/pedagogic/calendar?q=${record.relation_key}`"
              >#{{ record.relation_key }}</b-link
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="record.file_key">
        <b-col md="3">
          <p
            @click="getOpenURLFileOccurence(record.file_key)"
            class="file-card mt-50"
          >
            <feather-icon size="20" class="mr-50" icon="FileTextIcon" />
            <span class="text-truncate">Arquivo Vinculado</span>
          </p>
        </b-col>
      </b-row>
    </b-form>
  </viewcard--c>
</template>
<script>
import _jobOccurrenceService from "@/services/audiovisual/job-occurrence-service";
import _occurrenceCategoryService from "@/services/audiovisual/occurrence-category-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile, BLink } from "bootstrap-vue";
import _mediaFilesService from "@/services/audiovisual/media-files-service";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BLink,
  },
  data() {
    return {
      btedit: { permission: `permission.job.occurrence.edit` },
      btcreate: { permission: `permission.job.occurrence.create` },
      btdelete: { permission: `permission.job.occurrence.delete` },
      loading: false,
      record: {
        id: 0,
        description: "",
        occurrenceCategory: "",
      },
      OccurrenceCategory: [],
      OccurrenceCategorySelected: { label: "categorias....", value: "-3" },
      TYPE_MEDIA_FILE_OCCURRENCE: 3,
    };
  },
  created() {
    localize("pt_BR", pt_br);
    this.getRecord();
  },
  methods: {
    validationForm() {
      this.$refs.formatRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _jobOccurrenceService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      if (this.OccurrenceCategorySelected) {
        this.record.occurrence_category_id = this.$utils.getValueSelected(
          this.OccurrenceCategorySelected
        );
        this.record.occurrenceCategory = {
          name: this.OccurrenceCategorySelected.label,
        };
      }

      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _jobOccurrenceService.create(payload)
          : _jobOccurrenceService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getOpenURLFileOccurence(file_s3_key) {
      if (file_s3_key) {
        _mediaFilesService
          .getUrlFile(file_s3_key, this.TYPE_MEDIA_FILE_OCCURRENCE)
          .then((res) => {
            if (res.content) {
              window.open(res.content, "_blank");
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
  },
};
</script>
<style scoped lang="scss">
.file-card {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 15px;
  background-color: rgb(234, 253, 234);
  border-radius: 5px;
  max-width: 296px;
  margin: 2px;
}
</style>